export const API_URL_RESEND_EMAIL = '/api/v2/auths/register/resend'
export const API_URL_VERIFY_REGISTER = 'api/v2/auths/register/verify'
export const API_URL_LOGIN = '/api/v2/auths/login'
export const API_URL_REGISTER = '/api/v2/auths/register'
export const API_URL_CANDIDATE_REGISTER = '/api/v2/auths/register/candidate'
export const API_URL_FETCH_ME = '/api/v2/auths/me'

export const API_URL_CANDIDATE_FETCH_ME = '/api/v2/candidates/me'
export const API_URL_CONSULTANT_FETCH_ME = '/api/v2/consultants/me'
export const API_URL_COMPANY_FETCH_ME = '/api/v2/companies/me'
export const API_URL_ADMIN_FETCH_ME = '/api/v2/admins/me'

export const API_URL_RESET_PASSWORD = 'api/v2/auths/forgot-password'
export const API_URL_ME = '/api/v2/auths/me'
export const API_URL_SAVE_JOB = '/api/v2/auths/me'

export const API_URL_FORGOT_PW_SEND_EMAIL = 'api/v2/auths/forgot-password/send'
export const API_URL_FORGOT_PW_VERIFY_TOKEN = 'api/v2/auths/forgot-password/verify'

export const API_URL_SUBMIT_PROFILE = '/api/v2/companies/companies'
export const API_URL_SUBMIT_ONBOARDING = '/api/v2/companies/companies'
export const API_URL_INDUSTRIES = '/api/v2/public/industries'
export const API_URL_UPDATE_PASSWORD = '/api/v2/candidates/accounts/reset-password'
export const API_URL_ACCOUNT = '/api/v2/account'

export const API_URL_UPLOAD_PHOTO = 'api/v2/medias/file'
export const API_URL_UPLOAD_IMAGE = 'api/v2/medias/image'
export const API_URL_SKILLS = '/api/v2/public/skills'
export const API_URL_JOB_CATEGORIES = '/api/v2/public/job_categories'
export const API_URL_CREATE_JOB = '/api/v2/companies/jobs'
export const API_URL_LIST_JOB = 'api/v2/companies/jobs'
export const API_URL_GET_JOB_DETAIL = '/api/v2/companies/jobs'
export const API_URL_GET_JOB_CONSULTANTS_DETAIL = '/api/v2/consultants/jobs'

export const API_URL_GET_PROFILE_COMPANY = '/api/v2/companies/current_company'
export const API_URL_GET_PROFILE_CONSULTANTS = '/api/v2/consultants/current_company'
export const API_URL_GET_PROFILE_CANDIDATE = '/api/v2/candidates/current_company'
export const API_URL_GET_PROFILE_ADMIN = '/api/v2/admin/current_company'

export const API_URL_LIST_TEAM_MEMBER = '/api/v2/companies/members'
export const API_URL_INVITE_TEAM_MEMBER = '/api/v2/companies/members/invites'
export const API_URL_RESEND_INVITE_MEMBER_EMAIL = '/api/v2/companies/members/invites/resend'
export const API_URL_VERIFY_MEMBER_INVITE = '/api/v2/companies/members/invites/verify'
export const API_URL_UPDATE_MEMBER_LOGIN = '/api/v2/companies/members/invites/update'
export const API_URL_CANDIDATES_JOB = '/api/v2/candidates/jobs'
export const API_URL_CANDIDATES_MYJOB_SAVE = '/api/v2/candidates/jobs/save'
export const API_URL_CANDIDATES_MYJOB_APPLY = '/api/v2/candidates/jobs/apply'
export const API_URL_PUBLISHCOMPY = '/api/v2/public/companies/123'
export const API_URL_CANDIDATES_REGISTER_SOCIAL = 'api/v2/auths/register/social'
export const API_URL_CANDIDATES_LOGIN_SOCIAL = 'api/v2/auths/login/social'
export const API_URL_CANDIDATES_CVS = '/api/v2/candidates/cvs'
export const API_URL_CANDIDATES_JOB_SUGGESTION = `/api/v2/candidates/jobs/suggested_jobs`
export const API_URL_CANDIDATES_APPLY_JOB = (jobId: number): string => `/api/v2/candidates/jobs/${jobId}/apply`
export const API_URL_CLIENT_INFO_FROM_TOKEN = (token: string): string => `/api/v2/consultants/clients/info?token=${token}`
export const API_URL_CLIENT_ACTIVE = `/api/v2/consultants/clients/active`

export const API_URL_FETCH_ME_CANDIDATE = '/api/v2/auths/me'
export const API_FETCH_LOCATION = '/api/v2/public/locations'
export const API_URL_PROFILE = '/api/v2/public/profile'
export const API_CANDICATE_PROFILE = '/api/v2/candidates/profile'
export const API_CANDIDATE_PROFILE_SUBSCRIBE_JOB_SUGGESTION = '/api/v2/candidates/profile/subscribe_job_suggestions'
export const API_URL_APPLICANTS = '/api/v2/companies/applicants'
export const API_URL_APPLICANTS_CONSULTANTS = '/api/v2/consultants/applicants'

export const API_URL_COMPANY_JOB_STAGE_SEARCH_LIST = '/api/v2/companies/jobs/stages'
export const API_URL_COMPANY_CREATE_CLIENT = 'api/v2/consultants/clients'
export const API_URL_LIST_ACCOUNT_MANAGERS = 'api/v2/consultants/account_managers'
export const API_URL_CONSULTANTS_COMPANIES = 'api/v2/consultants/companies'
export const API_URL_COMPANY_CREATE_CLIENT_ASSIGN = 'api/v2/consultants/clients/assign'

export const API_URL_CONSULTANT_CREATE_JOB_4_CLIENT = `/api/v2/consultants/jobs`
export const API_URL_CONSULTANT_GET_JOB_DETAIL = `/api/v2/consultants/jobs`

export const API_URL_CONSULTANT_GET_JOBS = `/api/v2/consultants/jobs`
export const API_URL_CONSULTANT_GET_ACTIVE_JOBS = `/api/v2/consultants/jobs/actives`
export const API_URL_CONSULTANT_GET_SOURCE = `/api/v2/consultants/candidates`
export const API_URL_LOCATION = '/api/v2/public/locations'
export const API_URL_COMPANIES_MEMBERS = '/api/v2/companies/members?fetch_id=true'
export const API_URL_CONSULTANT_CLIENTS = '/api/v2/consultants/clients?fetch_id=true'
export const API_URL_CONSULTANT_GET_JOB_ORDERS = `/api/v2/consultants/job_orders`

export const API_URL_PUBLIC_APPLY_JOB = function (jobId: number): string {
  return `/api/v2/public/jobs/${jobId}/apply`
}

export const API_URL_PUBLIC_APPLY_JOB_BY_SOCIAL = function (jobId: number): string {
  return `/api/v2/public/jobs/${jobId}/social/apply`
}

export const API_URL_PUBLIC_APPLY_JOB_WITH_SOCIAL_WITHOUT_CV = `/api/v2/auths/social_quick_apply`

export const API_URL_COMPANY_MEMBER = `/api/v2/companies/members?active=true&all=1`

export const API_URL_CONSULTANT_CLIENT = `/api/v2/consultants/clients?status=1`
export const API_URL_JOBS_CANDIDATES = (jobId: number | string | string[]): string => {
  return `/api/v2/companies/jobs/${jobId}/candidates`
}
export const API_URL_CONTACT_REQUEST = '/api/v2/public/contact_request'
export const API_URL_CONTACT_REQUEST_LOGIN = '/api/v2/companies/contact_requests'

export const API_URL_CONSULTANT_CREATE_PROFILE = `/api/v2/consultants/candidates/profile`

export const API_URL_COMPANY_JOB_STAGE = (jobId: number | string | string[]): string => {
  return `/api/v2/companies/jobs/${jobId}/stages`
}
export const API_URL_COMPANY_APPLICANTS_STAGE = (jobId: number | string | string[]): string => {
  return `/api/v2/companies/applicants/${jobId}/stages/update`
}

export const API_URL_CONSULTANT_COMPANY_BLOCK = (companyId: number): string => `/api/v2/consultants/companies/${companyId}/block`
export const API_URL_CONSULTANT_APPLICANTS_DISQUALIFY = (candidateId: number | string): string =>
  `/api/v2/consultants/applicants/${candidateId}/disqualify`

export const API_URL_COMPANY_CANDIDATE_EVALUATION = (applicantId: string): string =>
  `/api/v2/companies/applicants/${applicantId}/evaluations`
export const API_URL_COMPANY_CANDIDATE_EVALUATION_GET = (applicantId: string | number, id: string): string =>
  `/api/v2/companies/applicants/${applicantId}/evaluations/${id}`
export const API_URL_JOB_NOTES = (jobId: number | string | string[]): string => {
  return `/api/v2/companies/jobs/${jobId}/notes`
}
export const API_URL_UPDATE_JOB_NOTES = (jobId: number | string | string[], id: number | string | string[]): string => {
  return `/api/v2/companies/jobs/${jobId}/notes/${id}`
}
export const API_URL_DELETE_CV = (profileId: number | string | string[], id: number | string | string[]): string => {
  return `/api/v2/consultants/candidates/${profileId}/cv/${id}`
}
export const API_URL_APPLICANTS_NOTES = (jobId: number | string | string[]): string => {
  return `/api/v2/companies/applicants/${jobId}/notes`
}
export const API_URL_UPDATE_APPLICANTS_NOTES = (jobId: number | string | string[], id: number | string | string[]): string => {
  return `/api/v2/companies/applicants/${jobId}/notes/${id}`
}
export const API_URL_COMPANY_JOBS = `/api/v2/companies/jobs?fetch_id=true&status_arr[]=1&status_arr[]=2&freec_status_arr[]=1&freec_status_arr[]=3`
export const API_SHARE_PROFILE = (token: string | string[]): string => {
  return `/api/v2/public/applicants/${token}/profile`
}
export const API_URL_TALENT_POOL = `/api/v2/public/talent_pools`
export const API_URL_EDIT_TALENT_POOL = (candidateId: number | string | string[]): string => {
  return `api/v2/consultants/candidates/${candidateId}/talent-pool`
}
export const API_URL_FETCH_INFO_APPLICANTS = `/api/v2/consultants/applicants/info`
export const API_URL_SEND_CANDIDATES = `/api/v2/consultants/applicants/sent_to_clients`
export const API_URL_POST_INTERVIEWS_APPLICANTS = (applicantId: number | string | string[]): string => {
  return `/api/v2/companies/applicants/${applicantId}/interviews`
}
export const API_URL_SHOW_AN_INTERVIEW = (applicantId: number | string | string[], interviewId: number | string | string[]): string => {
  return `/api/v2/companies/applicants/${applicantId}/interviews/${interviewId}`
}
export const API_URL_GET_LIST_INTERVIEW = `/api/v2/companies/interviews`
export const API_URL_CANCEL_INTERVIEW = (applicant_id: number | string, interview_id: number | string): string => {
  return `/api/v2/companies/applicants/${applicant_id}/interviews/${interview_id}/cancel`
}

export const API_URL_INTERVIEW_APPLICANT = (applicant_id: number | string): string => {
  return `/api/v2/companies/applicants/${applicant_id}/interviews`
}

export const API_URL_REMOVE_MEMBER = (id_member: string | string[] | number) => `/api/v2/companies/members/${id_member}/active`
export const API_URL_CHECK_VALIDATION_EMAIL = `/api/v2/consultants/candidates/check_email`
export const API_URL_CLIENT_NOTES = (clientId: number | string | string[]): string => {
  return `/api/v2/consultants/clients/${clientId}/notes`
}
export const API_URL_UPDATE_CLIENT_NOTES = (clientId: number | string | string[], noted_id: number | string | string[]): string => {
  return `/api/v2/consultants/clients/${clientId}/notes/${noted_id}`
}

export const API_URL_CLIENT_JOB_ORDERS = (clientId: number | string | string[]): string => {
  return `/api/v2/consultants/clients/${clientId}/job_orders`
}

export const API_URL_CLIENT_OPEN_JOB = (clientId: number | string | string[]): string => {
  return `/api/v2/consultants/clients/${clientId}/opened_jobs`
}

export const API_URL_CLIENT_DETAIL = (clientId: number | string | string[]): string => {
  return `/api/v2/consultants/clients/${clientId}/detail`
}

export const API_URL_ASSIGN_OWNERSHIP = (id: string | string[] | number) => `/api/v2/consultants/applicants/${id}/assign_ownership`

export const API_URL_COMPANY_LIST = '/api/v2/public/published_company'

export const API_URL_CONSULTANT_CREATE_OFFER = (applicant_id: number | string | string[]) =>
  `/api/v2/companies/applicants/${applicant_id}/offers`
export const API_URL_CONSULTANT_APPLICANT_OFFER = (applicant_id: number | string | string[], id: number | string | string[]) =>
  `/api/v2/companies/applicants/${applicant_id}/offers/${id}`
export const API_URL_EMAILTEMPLATE_DETAIL = (id: string | string[] | number) => `/api/v2/companies/email_templates/${id}`

export const API_URL_OFFER_UPDATE_STATUS = (applicant_id: string | string[] | number, id: number | string | string[]) =>
  `/api/v2/companies/applicants/${applicant_id}/offers/${id}/update_status`
export const API_URL_PLACEMENT_CREATE = (applicant_id: string | string[] | number) =>
  `/api/v2/companies/applicants/${applicant_id}/placements`
export const API_URL_PLACEMENT_UPDATE_STATUS = (applicant_id: string | string[] | number, id: number | string | string[]) =>
  `/api/v2/companies/applicants/${applicant_id}/placements/${id}/update_status`
export const API_URL_PLACEMENT_LIST = `/api/v2/companies/placements`
export const API_URL_PLACEMENT_DETAIL = (applicant_id: string | string[] | number, id: number | string | string[]) =>
  `/api/v2/companies/applicants/${applicant_id}/placements/${id}`
export const API_URL_PLACEMENT_UPDATE = (applicant_id: string | string[] | number, id: number | string | string[]) =>
  `/api/v2/companies/applicants/${applicant_id}/placements/${id}`
export const API_URL_CONSULTANT_OFFERS_LIST = `/api/v2/companies/offers`

export const API_URL_CONSULTANT_PLACEMENT = (client_id: string | number | string[]) => `/api/v2/consultants/clients/${client_id}/placements`

export const API_URL_PREMIUM_COMPANY = (company_id: string | number | string[]) => `/api/v2/public/branded_company/${company_id}`
export const API_CONSULTANT_COMPANY_DETAIL = (company_id: string | number | string[]) => `/api/v2/consultants/companies/${company_id}`
export const API_CONSULTANT_COMPANIES_JOB_POST = (id: string | string[] | number) => `/api/v2/consultants/companies/${id}/job_posts`
export const API_CONSULTANT_COMPANIES_JOB_ORDERS = (id: string | string[] | number) => `/api/v2/consultants/companies/${id}/job_orders`
export const API_CONSULTANT_UPDATE_COMPANY = (company_id: string | string[] | number) =>
  `/api/v2/consultants/companies/${company_id}/update`

export const API_ADMIN_COMPANY_LIST = '/api/v2/admins/companies'
export const API_URL_ADMIN_GET_JOBS = `/api/v2/admins/jobs`
export const API_URL_ADMIN_CREATE_JOB = `/api/v2/admins/jobs`
export const API_URL_LIST_TALENT_POOL = `/api/v2/consultants/talent_pools`
export const API_URL_CREATE_TALENT_POOL = `/api/v2/consultants/talent_pools`
export const API_URL_TALENT_POOL_DETAIL = (id: string | string[] | number) => `/api/v2/consultants/talent_pools/${id}`
export const API_URL_PROFILE_WITHOUT_CV = '/api/v2/candidates/profile/quick_apply_profile'
export const API_CONSULTANT_CONTACT_REQUEST_LIST = `/api/v2/consultants/contact_requests`
export const API_CONSULTANT_CONTACT_REQUEST = (company_id: string | string[] | number) =>
  `/api/v2/consultants/companies/${company_id}/contact_requests`

export const API_URL_CANDIDATES_JOB_ALERT = '/api/v2/candidates/job_alerts'
export const API_URL_CREATE_JOB_ALERT = function (isLogined: boolean): string {
  return isLogined ? '/api/v2/candidates/job_alerts' : '/api/v2/public/job_alerts'
}

export const API_URL_EDIT_JOB_ALERT = (id) => `/api/v2/candidates/job_alerts/${id}`

export const API_URL_RECRUITER_APPLICANTS_DISQUALIFY = (candidateId: number | string): string =>
  `/api/v2/companies/applicants/${candidateId}/disqualify`
export const API_URL_PUBLIC_RECOMMEND_JOB = `/api/v2/candidates/jobs/recommend_jobs`

export const API_CONSULTANT_GET_ALL_COMPANY_DIRECTORIES = '/api/v2/candidates/company_directories'
export const API_CONSULTANT_GET_SEARCH_COMPANY_DIRECTORIES = '/api/v2/candidates/company_directories/search'
export const API_URL_TRACKING_CV_ONLINE_AHOY = `/api/v2/public/ahoy`
export const API_FOLLOW_COMPANIES = `/api/v2/candidates/follow_companies`
export const API_LIST_FOLLOW_COMPANIES = `/api/v2/candidates/follow_companies`
export const API_SUBSCRIBE_FOLLOW_COMPANIES = `/api/v2/candidates/follow_companies/subscribed`
export const API_UNSUBSCRIBE_FOLLOW_COMPANIES = `/api/v2/candidates/follow_companies/unsubscribed`
export const API_GET_SEARCH_COMPANY = `/api/v2/candidates/companies`
