import { createAPIClient } from '@freec/core/lib/utilities/APIClient/CreateApiClient'
import env from './env'
// eslint-disable-next-line no-restricted-imports
import Router from 'next/router'
import { LINK_URL_LOGIN } from './URL'
export default createAPIClient(
  env.PUBLIC_ENABLE_API_PROXY ? '/' : env.PUBLIC_API_END_POINT,
  () => {
    return Router.push(LINK_URL_LOGIN)
  },
  () => {
    return Router.push(LINK_URL_LOGIN)
  }
)
