/* eslint-disable @typescript-eslint/ban-ts-comment */
import axios, { AxiosInstance } from 'axios'
import { ClientResponse } from '../type/Common'
import { useEffect } from 'react'
import { API_URL_LOGIN } from './APIConstant'
import {
  setCookie,
  deleteCookie,
  getCookie,
  COOKIES_NAME_REFERRAL_ID,
  COOKIES_NAME_UTM_SOURCE,
  COOKIES_NAME_UTM_CAMPAIGN,
  COOKIES_NAME_UTM_MEDIUM
} from './Cookies'
// eslint-disable-next-line no-restricted-imports
import { NextRouter } from 'next/router'
export const X_AUTH_TOKEN = 'X_AUTH_TOKEN'
export const R_HEADER_X_REFERRAL_ID = 'referral-id'
interface MatchKeyValue {
  matchCondition: { [key: string]: any }
  error?: any
  response?: any
}
interface MockOptions {
  matchParamsResponse?: MatchKeyValue
  matchDataResponse?: MatchKeyValue
}

export interface ClientType {
  get: (url: string, data: any) => Promise<ClientResponse>
  post: (url: string, data: any) => Promise<ClientResponse>
  put: (url: string, data: any) => Promise<ClientResponse>
  patch: (url: string, data: any) => Promise<ClientResponse>
  delete: (url: string, data: any) => Promise<ClientResponse>
  login: (data: { email: string; password: string }, preventRedirect: boolean) => Promise<ClientResponse>
  register_quick_apply: (
    data: { language: number; from_product: number; full_name: string; email: string; password: string },
    preventRedirect: boolean
  ) => Promise<ClientResponse>
  logout: () => Promise<any>
  setHistory: (his: any) => void
  getToken: () => string | null
  setToken: (token: any) => void
  mock: (arg?: MockOptions) => ClientType
}
const instance = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.NEXT_PUBLIC_ENABLE_API_PROXY ? '' : process.env.NEXT_PUBLIC_API_END_POINT,
  // timeout: "",
  withCredentials: true
})
const mockInstance = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: ''
  // timeout: "",
})

let router: NextRouter
let on401
let on403
export const setOn401 = (handleFunc) => {
  on401 = handleFunc
}
export const setOn403 = (handleFunc) => {
  on403 = handleFunc
}
const createRequest = (axiosInstance: AxiosInstance, mockOptions?: MockOptions) => (apiPath: any, requestConfig: any): any => {
  const headers = { ...requestConfig.headers }
  // headers['Accept-Language'] = 'en'
  const utmPrams = {}
  if (!requestConfig.ignoreAuthentication && getCookie(X_AUTH_TOKEN)) {
    headers.Authorization = `${getCookie(X_AUTH_TOKEN)}`
  }
  if (getCookie(COOKIES_NAME_REFERRAL_ID)) {
    headers[R_HEADER_X_REFERRAL_ID] = getCookie(COOKIES_NAME_REFERRAL_ID)
  }
  if (getCookie(COOKIES_NAME_UTM_SOURCE)) {
    headers[COOKIES_NAME_UTM_SOURCE] = getCookie(COOKIES_NAME_UTM_SOURCE)
    utmPrams[COOKIES_NAME_UTM_SOURCE] = getCookie(COOKIES_NAME_UTM_SOURCE)
  }
  if (getCookie(COOKIES_NAME_UTM_CAMPAIGN)) {
    headers[COOKIES_NAME_UTM_CAMPAIGN] = getCookie(COOKIES_NAME_UTM_CAMPAIGN)
    utmPrams[COOKIES_NAME_UTM_CAMPAIGN] = getCookie(COOKIES_NAME_UTM_CAMPAIGN)
  }
  if (getCookie(COOKIES_NAME_UTM_MEDIUM)) {
    headers[COOKIES_NAME_UTM_MEDIUM] = getCookie(COOKIES_NAME_UTM_MEDIUM)
    utmPrams[COOKIES_NAME_UTM_MEDIUM] = getCookie(COOKIES_NAME_UTM_MEDIUM)
  }
  if (mockOptions?.matchParamsResponse && requestConfig.params) {
    const matchParamsCondition = Object.keys(mockOptions.matchParamsResponse.matchCondition).filter(
      (key) => requestConfig.params[key] && requestConfig.params[key] === requestConfig.params[key]
    )
    if (matchParamsCondition.length > 0) {
      if (mockOptions.matchParamsResponse.error) {
        return Promise.reject({
          response: {
            status: 400,
            data: mockOptions.matchParamsResponse.response
          }
        })
      }
      return Promise.resolve({
        data: mockOptions.matchParamsResponse.response
      })
    }
  }
  if (mockOptions?.matchDataResponse && requestConfig.data) {
    const matchDataResponse = Object.keys(mockOptions.matchDataResponse.matchCondition).filter(
      (key) => requestConfig.data[key] && requestConfig.data[key] === mockOptions.matchDataResponse.matchCondition[key]
    )
    if (matchDataResponse.length > 0) {
      if (mockOptions.matchDataResponse.error) {
        return Promise.reject({
          response: {
            status: 400,
            data: mockOptions.matchDataResponse.error
          }
        })
      }
      return Promise.resolve({
        data: mockOptions.matchDataResponse.response
      })
    }
  }
  // debugger

  return axiosInstance
    .request({
      url: apiPath,
      ...requestConfig,
      params: { ...(requestConfig.sendUTM ? utmPrams : {}), ...requestConfig.params },
      headers
    })
    .then((response) => {
      return response
    })
    .catch((error) => {
      if (!requestConfig.preventRedirect && error.response && error.response.status === 401) {
        if (location.pathname.indexOf('/login') !== 0) {
          //@ts-ignore
          if (typeof window?.Gleap === 'function') {
            //@ts-ignore
            window.Gleap.clearIdentity()
          }
          deleteCookie(X_AUTH_TOKEN)
          localStorage.setItem('AUTHENTICATION_TOKEN', '')
          on401 && on401()
          // window.location.href = `/login`
        }
        throw new Error(`Server API 401`)
      }
      if (!requestConfig.preventRedirect && error.response && error.response.status === 403) {
        //@ts-ignore
        if (typeof window?.Gleap === 'function') {
          //@ts-ignore
          window.Gleap.clearIdentity()
        }
        deleteCookie(X_AUTH_TOKEN)
        localStorage.setItem('AUTHENTICATION_TOKEN', '')
        on403 && on403()
        // window.location.href = `/login`
        throw new Error(`Server API 403`)
      }
      if (error.response && error.response.status === 500) {
        throw new Error(`Server api call 500 Error ${apiPath}`)
      }
      throw error
    })
}
const request = createRequest(instance)

const createClient = (requestInstance): ClientType => {
  return {
    get: (apiPath, options = {}): any =>
      requestInstance(apiPath, {
        method: 'get',
        params: options.params,
        data: options.data,
        ...options.config
      }),
    post: (apiPath, options = {}) =>
      requestInstance(apiPath, {
        method: 'post',
        params: options.params,
        data: options.data,
        ...options.config
      }),
    put: (apiPath, options = {}) =>
      requestInstance(apiPath, {
        method: 'put',
        params: options.params,
        data: options.data,
        ...options.config
      }),
    patch: (apiPath, options = {}) =>
      requestInstance(apiPath, {
        method: 'patch',
        params: options.params,
        data: options.data,
        ...options.config
      }),
    delete: (apiPath, options = {}) =>
      requestInstance(apiPath, {
        method: 'delete',
        params: options.params,
        data: options.data,
        ...options.config
      }),
    setToken: (token: any) => {
      setCookie(X_AUTH_TOKEN, token, 10000)
      localStorage.setItem('AUTHENTICATION_TOKEN', `Bearer ${token}`)
    },
    getToken: () => {
      return getCookie(X_AUTH_TOKEN)
    },
    login: (data: { email: string; password: string }, preventRedirect?: boolean) => {
      return requestInstance(API_URL_LOGIN, {
        method: 'post',
        ignoreAuthentication: true,
        preventRedirect: preventRedirect || false,
        config: { sendUTM: true },
        data: { email: data.email.toLowerCase(), password: data.password }
      }).then((response: any) => {
        const accessToken = response.data.token
        const token = `Bearer ${accessToken}`
        localStorage.setItem('AUTHENTICATION_TOKEN', token)
        setCookie(X_AUTH_TOKEN, accessToken, 10000)
        return response
      })
    },
    register_quick_apply: (
      data: { language: number; from_product: number; full_name: string; email: string; password: string },
      preventRedirect?: boolean
    ) => {
      return requestInstance('/api/v2/auths/email_quick_apply', {
        method: 'post',
        ignoreAuthentication: true,
        preventRedirect: preventRedirect || false,
        data: {
          email: data.email.toLowerCase(),
          password: data.password,
          language: data.language,
          from_product: data.from_product,
          full_name: data.full_name
        }
      }).then((response: any) => {
        const accessToken = response.data.token
        const token = `Bearer ${accessToken}`
        localStorage.setItem('AUTHENTICATION_TOKEN', token)
        setCookie(X_AUTH_TOKEN, accessToken, 10000)
        return response
      })
    },
    logout: () => {
      //@ts-ignore
      if (typeof window?.Gleap === 'function') {
        //@ts-ignore
        window.Gleap.clearIdentity()
      }
      localStorage.setItem('AUTHENTICATION_TOKEN', '')
      deleteCookie(X_AUTH_TOKEN)
      return Promise.resolve()
    },
    setHistory: (bHistory) => {
      history = bHistory
    },
    mock: (arg) => {
      const mockRequest = createRequest(mockInstance, arg)
      return createClient(mockRequest)
    }
  }
}
const client = createClient(request)
export const useRoutingCollector = (injectedRouter: NextRouter): void => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    router = injectedRouter
  }, [])
}
export default client
