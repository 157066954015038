import JobCategoriesFixture from '../store/JobCategoriesFixture'
import LocationsFixture from '../store/LocationsFixture'
import { CategoryType, FreecLocationType } from '../type/Common'
import { LINK_URL_SEARCH, LINK_URL_SEARCH_VN } from './LinkURL'
import { nonAccentVietnamese } from './StringUtils'
const idCategoryMap: { [id: string]: CategoryType } = JobCategoriesFixture.data.reduce(
  (result, item) => ({
    ...result,
    [item.attributes.id]: item.attributes,
    [item.attributes.id]: item.attributes
  }),
  {}
)
const idLocationMap: { [id: string]: FreecLocationType } = LocationsFixture.data.reduce(
  (result, item) => ({
    ...result,
    [item.attributes.id]: item.attributes,
    [item.attributes.id]: item.attributes
  }),
  {}
)
const toSEOString = (text: string) => text.toLowerCase().replace(/\s+/g, ' ').replace(/&/g, '').trim().replace(/\s/g, '+')
const fromSEOString = (text: string) => {
  return text.toLowerCase().replace(/\+/g, ' ')
}
export function toSEOStringFromQuery(params?: string | string[]): string {
  return params ? (typeof params === 'string' ? toSEOString(params) : toSEOString(params[0])) : undefined
}
export function toStringFromSE0Query(params?: string | string[]): string {
  return params ? (typeof params === 'string' ? fromSEOString(params) : fromSEOString(params[0])) : undefined
}
export function deserializeSEOParams(params: object): any {
  return Object.keys(params).reduce(
    (final, key) => ({ ...final, [key]: typeof params[key] === 'string' ? fromSEOString(params[key]) : params[key] }),
    {}
  )
}
export const toHyperString = (text: string) => {
  return nonAccentVietnamese(text.toLowerCase())
    .replace(/[&]/g, ' vs ')
    .replace(/[-]/g, '')
    .replace(/\s+/g, ' ')
    .replace(/[&/]/g, '')
    .replace(/[&/,()$~%.'":*?<>{}]/g, '')
    .trim()
    .replace(/\s/g, '-')
}

export const fromHyperString = (text: string) => text.toLowerCase().replace(/-/g, ' ')

export type JobQueryType = {
  search?: string
  locationId?: number | string
  categoryId?: number | string
}

export const getJobQueryFromSEOPathName = (
  pathName: string,
  idCategoryMap: { [id: string]: CategoryType },
  idLocationMap: { [id: string]: FreecLocationType }
): JobQueryType => {
  const locationMatcher = /-l(\d+)/g.exec(pathName)
  const locationId = locationMatcher && locationMatcher[1]
  const categoryMatcher = /-c(\d+)/g.exec(pathName)
  const categoryId = categoryMatcher && categoryMatcher[1]
  const laspathMatch = pathName.match(/[^/]+\/([^/]+)$/)
  let search = laspathMatch ? decodeURIComponent(laspathMatch[1]) : ''
  if (locationId && idLocationMap[locationId]) {
    search = search.replace(new RegExp(`${toHyperString(`in ${idLocationMap[locationId].state}`)}`, 'g'), '')
    search = search.replace(new RegExp(`${toHyperString(`tai ${idLocationMap[locationId].state_vn}`)}`, 'g'), '')
    search = search.replace(new RegExp(`${toHyperString(`${idLocationMap[locationId].state_vn}`)}`, 'g'), '')
    search = search.replace(new RegExp(`${toHyperString(`${idLocationMap[locationId].state}`)}`, 'g'), '')
    search = search.replace(new RegExp(`-l${locationId}`, 'g'), '')
  }
  if (categoryId && idCategoryMap[categoryId]) {
    search = search.replace(new RegExp(`${toHyperString(idCategoryMap[categoryId].name)}`, 'g'), '')
    search = search.replace(new RegExp(`${toHyperString(idCategoryMap[categoryId].name_vn)}`, 'g'), '')
    search = search.replace(new RegExp(`-c${categoryId}`, 'g'), '')
  }
  return { search: fromHyperString(search).trim(), locationId: locationId, categoryId: categoryId }
}
export const getJobQueryFromSEOPathNameV2 = (pathName: string): JobQueryType => {
  return getJobQueryFromSEOPathName(pathName, idCategoryMap, idLocationMap)
}
export const toSEOPathName = (
  jobQuery: JobQueryType,
  currentLang: string,
  idCategoryMap: { [id: string]: CategoryType },
  idLocationMap: { [id: string]: FreecLocationType }
) => {
  const prePath = currentLang === 'vi' ? `/viec-lam` : `/jobs`
  const inWord = currentLang === 'vi' ? 'tai' : 'in'
  if (jobQuery.search && jobQuery.locationId && jobQuery.categoryId) {
    const locationString = currentLang === 'vi' ? idLocationMap[jobQuery.locationId]?.state_vn : idLocationMap[jobQuery.locationId]?.state
    return `${prePath}/${toHyperString(jobQuery.search)}-${inWord}-${toHyperString(locationString)}-l${jobQuery.locationId}-c${
      jobQuery.categoryId
    }`
  }
  if (jobQuery.search && jobQuery.locationId) {
    const locationString = currentLang === 'vi' ? idLocationMap[jobQuery.locationId]?.state_vn : idLocationMap[jobQuery.locationId]?.state
    return `${prePath}/${toHyperString(jobQuery.search)}-${inWord}-${toHyperString(locationString)}-l${jobQuery.locationId}`
  }
  if (jobQuery.search && jobQuery.categoryId) {
    return `${prePath}/${toHyperString(jobQuery.search)}-c${jobQuery.categoryId}`
  }
  if (jobQuery.locationId && jobQuery.categoryId) {
    const locationString = currentLang === 'vi' ? idLocationMap[jobQuery.locationId]?.state_vn : idLocationMap[jobQuery.locationId]?.state
    const categoryString = currentLang === 'vi' ? idCategoryMap[jobQuery.categoryId]?.name_vn : idCategoryMap[jobQuery.categoryId]?.name
    return `${prePath}/${toHyperString(categoryString)}-${inWord}-${toHyperString(locationString)}-l${jobQuery.locationId}-c${
      jobQuery.categoryId
    }`
  }
  if (jobQuery.locationId) {
    const locationString = currentLang === 'vi' ? idLocationMap[jobQuery.locationId]?.state_vn : idLocationMap[jobQuery.locationId]?.state
    return `${prePath}/${toHyperString(locationString)}-l${jobQuery.locationId}`
  }
  if (jobQuery.categoryId) {
    const categoryString = currentLang === 'vi' ? idCategoryMap[jobQuery.categoryId]?.name_vn : idCategoryMap[jobQuery.categoryId]?.name
    return `${prePath}/${toHyperString(categoryString)}-c${jobQuery.categoryId}`
  }
  if (jobQuery.search) {
    return `${prePath}/${toHyperString(jobQuery.search)}`
  }
  return prePath
}
export const getJobQueryFromSEOPathNameWithLocalMap = (pathName: string): JobQueryType => {
  return getJobQueryFromSEOPathName(pathName, idCategoryMap, idLocationMap)
}
export const toSEOPathNameV2 = (jobQuery: JobQueryType, language: string) => {
  return toSEOPathName(jobQuery, language, idCategoryMap, idLocationMap)
}

export const toLocationName = (locaionId: number, language: string) =>
  language === 'vi' ? idLocationMap[locaionId]?.state_vn : idLocationMap[locaionId]?.state
export const toCategoryName = (categoryId: number, language: string) =>
  language === 'vi' ? idCategoryMap[categoryId]?.name_vn : idCategoryMap[categoryId]?.name
export const prePathSearch = (currentLanguage) => (currentLanguage === 'vi' ? LINK_URL_SEARCH_VN : LINK_URL_SEARCH)
