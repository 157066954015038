// eslint-disable-next-line no-restricted-imports
import Link, { LinkProps } from 'next/link'
import React, { FC } from 'react'
import config from '../../../../../config'
import { useTranslation } from '../../hooks/UserTranslation'
import { match, compile } from 'path-to-regexp'
import { getJobQueryFromSEOPathNameV2, toSEOPathNameV2 } from '../../utilities/SEOUntilities'
import { removeEndSplash } from '../../utilities/StringUtils'
const languageMap = config.routing.translates.reduce((result, item) => ({ ...result, [item.vi]: 'vi', [item.en]: 'en' }), {})
const LinkResolverHandleMap = {
  JobSearchSlugName: (pathName, lang) => {
    const { search, locationId, categoryId } = getJobQueryFromSEOPathNameV2(pathName)
    return toSEOPathNameV2({ search, locationId, categoryId }, lang)
  }
}
export const translateRouterHref = (href: string, lang: string) => {
  const [pathName, searchQuery] = href.split('?')

  const translateRouter = config.routing.translates.find((tranR) => {
    return match(tranR.default)(pathName) || (tranR.vi && match(tranR.vi)(pathName)) || (tranR.en && match(tranR.en)(pathName))
  })
  if (translateRouter) {
    if (translateRouter.linkResolvers) {
      const rotuerHandle = translateRouter.linkResolvers.find((item) => {
        return item.notMatch && !pathName.match(new RegExp(item.notMatch))
      })
      if (rotuerHandle) {
        const resolvedHref = LinkResolverHandleMap[rotuerHandle.handler](pathName, lang)
        const matcherSource =
          match<any>(translateRouter.default)(resolvedHref) ||
          (translateRouter.en && match<any>(translateRouter.en)(resolvedHref)) ||
          (translateRouter.vi && match<any>(translateRouter.vi)(resolvedHref))
        if (!translateRouter[lang]) {
          return resolvedHref + (searchQuery ? `?${searchQuery}` : ``)
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        const translateHref = compile(translateRouter[lang])(matcherSource.params)
        return translateHref + (searchQuery ? `?${searchQuery}` : ``)
      }
    }

    const matcherSource =
      match<any>(translateRouter.default)(pathName) ||
      (translateRouter.en && match<any>(translateRouter.en)(pathName)) ||
      (translateRouter.vi && match<any>(translateRouter.vi)(pathName))
    if (!translateRouter[lang]) {
      return href
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const translateHref = compile(translateRouter[lang])(matcherSource.params)
    return translateHref + (searchQuery ? `?${searchQuery}` : ``)
  }
  return href
}
export const getTranslatableLinks = (pathName: string) => {
  const translateRouter = config.routing.translates.find((tranR) => {
    return match(tranR.default)(pathName) || (tranR.vi && match(tranR.vi)(pathName)) || (tranR.en && match(tranR.en)(pathName))
  })
  if (translateRouter) {
    if (translateRouter.linkResolvers) {
      const rotuerHandle = translateRouter.linkResolvers.find((item) => {
        return item.notMatch && !pathName.match(new RegExp(item.notMatch))
      })
      if (rotuerHandle) {
        return Object.keys(translateRouter).map((lang) => {
          const resolvedHref = LinkResolverHandleMap[rotuerHandle.handler](pathName, lang)
          const matcherSource =
            match<any>(translateRouter.default)(resolvedHref) ||
            (translateRouter.en && match<any>(translateRouter.en)(resolvedHref)) ||
            (translateRouter.vi && match<any>(translateRouter.vi)(resolvedHref))
          //eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          return { lang, url: `${removeEndSplash(compile(translateRouter[lang])(matcherSource.params))}` }
        })
      }
    }

    const matcherSource =
      match<any>(translateRouter.default)(pathName) ||
      (translateRouter.en && match<any>(translateRouter.en)(pathName)) ||
      (translateRouter.vi && match<any>(translateRouter.vi)(pathName))
    return Object.keys(translateRouter).map((lang) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      return { lang, url: `${removeEndSplash(compile(translateRouter[lang])(matcherSource.params))}` }
    })
  }
  return []
}

export const getPathSpecificLanguage = (href: string) => {
  const matchingPath = Object.keys(languageMap).find((pattern) => match(pattern)(href))
  return matchingPath && languageMap[matchingPath]
}
export const AppLink: FC<LinkProps & { disable?: boolean; hardReload?: boolean }> = (props) => {
  const href = props.href
  const { currentLanguage } = useTranslation()
  const translateHref = translateRouterHref(href as string, currentLanguage)
  if (props.disable) {
    return <>{props.children}</>
  }
  return props.hardReload && props.children ? (
    <>
      {React.Children.map(props.children, (child) => {
        // Checking isValidElement is the safe way and avoids a typescript
        // error too.
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { href: translateHref || href })
        }
        return child
      })}
    </>
  ) : (
    <Link prefetch={false} {...props} as={translateHref} locale={false} />
  )
}
