import { FastJsonObject } from '../types/common'
import { ProfileType } from '../types/server/user/ProfileType'
import { API_URL_CANDIDATE_FETCH_ME } from '../utillities/APIConstant'
import Client from '../utillities/Client'
import { X_AUTH_TOKEN } from '../utillities/CONSTANT_COOKIES'
import { getCookie } from '../utillities/Cookies'
import { createGlobalDataHooks } from './share/CreateGlobalDataHooks'

export const [useCurrentUserStorage, setUser, , , clearData, refresh] = createGlobalDataHooks<ProfileType | undefined>(
  // 'current-user',
  () => {
    if (getCookie(X_AUTH_TOKEN)) {
      return Client.get<FastJsonObject<ProfileType>>(API_URL_CANDIDATE_FETCH_ME, {}).then((response) => {
        return response.data.data.attributes
      })
    }
    return Promise.resolve(undefined)
  },
  undefined
)

export const useCurrentUser = () => {
  const [data] = useCurrentUserStorage()
  return [data]
}
export const clearUser = () => clearData(undefined)
export const refreshUserData = refresh
export default useCurrentUser
