import { useCallback, useEffect, useState } from 'react'
import { createSimpleEmitter } from '../../utillities/Emitter'

export function createGlobalDataHooks<T>(
  fetchData: () => Promise<T>,
  initData: T
): [
  (changeDependency?: any, lazy?: boolean) => [T, boolean, () => void],
  (args: T) => Promise<void[]>,
  () => T,
  () => void,
  (data?: T) => void,
  () => Promise<any>
] {
  const { emitAll, regiterCallback, unregisterCallBack } = createSimpleEmitter<T>()
  const {
    emitAll: emitAllFetching,
    regiterCallback: regiterFetchingCallback,
    unregisterCallBack: unregisterFetchingCallBack
  } = createSimpleEmitter<boolean>()

  const dataHolder: { data: T; fetched: boolean; fetching: boolean } = {
    data: initData,
    fetched: false,
    fetching: false
  }
  const fetchDataQuee = {
    listResolve: [],
    doQueue() {
      return new Promise<T>(function (resolve) {
        if (!dataHolder.fetching && !dataHolder.fetched) {
          dataHolder.fetching = true
          emitAllFetching(true)
          fetchData().then(function (rs) {
            dataHolder.fetching = false
            if (rs) {
              dataHolder.fetched = true
              emitAllFetching(false)
              resolve(rs)
            }
          })
        }
      })
    }
  }

  const setDataState = (state: T) => {
    dataHolder.data = state
    emitAll(dataHolder.data)
  }
  const clearData = (state: T) => {
    dataHolder.data = state
    dataHolder.fetched = false
    emitAll(dataHolder.data)
  }
  return [
    function useGlobalState(changeDependency = [], lazy = false) {
      const [state, setState] = useState<T>(dataHolder.data)
      const [fetching, setFetching] = useState(!dataHolder.fetched)
      const fetchData = useCallback(() => {
        if (!dataHolder.fetched) {
          fetchDataQuee.doQueue().then((rs) => {
            setDataState(rs)
          })
        }
      }, [])
      useEffect(() => {
        if (!lazy) {
          fetchData()
        }
      }, [])

      const onEvent = (args: T) => {
        return Promise.resolve(setState(args))
      }
      const onFetchingEvent = (args: boolean) => {
        return Promise.resolve(setFetching(args))
      }
      useEffect(() => {
        regiterCallback(onEvent)
        regiterFetchingCallback(onFetchingEvent)
        return () => {
          unregisterCallBack(onEvent)
          unregisterFetchingCallBack(onFetchingEvent)
        }
      }, [...changeDependency, regiterCallback, unregisterCallBack])
      return [state, fetching, fetchData]
    },
    emitAll,
    () => dataHolder.data,
    () => (dataHolder.fetched = false),
    clearData,
    () => {
      clearData(undefined)
      return fetchDataQuee.doQueue().then((rs) => {
        dataHolder.fetched = false
        dataHolder.fetched = false
        emitAll(dataHolder.data)
        setDataState(rs)
        return rs
      })
    }
  ]
}
